import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import Footer from './shared/Footer';
import axios from 'axios';
import { withTranslation } from "react-i18next";



class App extends Component {
   state = {
     isLoggedIn: localStorage.getItem("loggedIn"),
     data:[]
   }
   componentDidMount() {
    this.onRouteChanged();
    if (this.props.location.pathname !== "/dashboard") {
    this.updateDash();
    }
}
updateDash() {
  const orderData = {"my_username":localStorage.getItem("username"),"authToken":localStorage.getItem("authToken")};
  axios.post("/masterapi/data.php",orderData).then( (res) => 

  {
// console.log(res.data);
this.setState({data:res.data});
      }
          ).catch(err => {
  
  });
}

  render () {
    let navbarComponent = !this.state.isFullPageLayout ? <Navbar data={this.state.data} /> : '';
    let sidebarComponent = !this.state.isFullPageLayout ? <Sidebar data={this.state.data}/> : '';
    let footerComponent = !this.state.isFullPageLayout ? <Footer/> : '';
    return (
      <div className="container-scroller">
        { navbarComponent }
        <div className="container-fluid page-body-wrapper">
          { sidebarComponent }
          <div className="main-panel">
            <div className="content-wrapper">
              <AppRoutes data={this.state.data}/>
            </div>
            { footerComponent }
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
      
    }

  }

  onRouteChanged() {
    if (this.props.location.pathname === "/dashboard" || this.props.location.pathname === "/new-order"  || this.props.location.pathname === "/settings" ) {
      this.setState({data:""});
this.updateDash();
    }

    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ['/login', '/error', '/logout','/login/','/error/','/logout/'];
    for ( let i = 0; i < fullPageLayoutRoutes.length; i++ ) {
      if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
        this.setState({
          isFullPageLayout: true
        })
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        document.querySelector('.content-wrapper').style.background = "none";
        break;
      } else {
        this.setState({
          isFullPageLayout: false
        })
        document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
        document.querySelector('.content-wrapper').style.background = "#f2edf3";
        

      }
    }
  }

}


export default withTranslation() (withRouter(App));
