import React, { Component } from 'react'
import { ProgressBar } from 'react-bootstrap';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import Loader from '../../assets/images/ajax-loader.gif';
import { withTranslation } from 'react-i18next';;
export class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
        username : this.props.data.username,
        email: this.props.data.email,
        telegram: this.props.data.telegram,
        d1: this.props.data.d1,
        d2: this.props.data.d2,
        d3: this.props.data.d3,
        d4: this.props.data.d4,
        d5: this.props.data.d5,
        sd: this.props.data.sd,
        authToken : this.props.data.authToken,
        saveBtn : "Save",
        domBtn : "Save"

};
  }


change = e =>{
this.setState({[e.target.name]: e.target.value})
};

Save = (e) => {
e.preventDefault();
if(e.target.name=="infoBtn"){
this.setState({saveBtn:"saving"});
}else if(e.target.name=="domBtn"){
  this.setState({domBtn:"saving"});
  }
const {username,email,telegram,d1,d2,d3,d4,d5,sd,authToken} = this.state;
const setData = {"username":username,"email":email,"telegram":telegram,"d1":d1,"d2":d2,"d3":d3,"d4":d4,"d5":d5,"sd":sd,"authToken":authToken};

axios.post("/masterapi/settings.php", setData).then((res) => {
 
if(res.data.save==="success"){

  Swal.fire({
    title: 'Saved',
    html: 'Settings saved successfully...' +
      '',
    showCloseButton: false,
    showCancelButton: false,
    focusConfirm: false,
    confirmButtonText: '<i class="fa fa-thumbs-up"></i> OK!',
    confirmButtonAriaLabel: 'OK',
    cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
    cancelButtonAriaLabel: 'Thumbs down'
  })
}else{
  Swal.fire({
    icon: 'error',
    title: 'Oops...',
    text: 'Something Went Wrong.!',
    footer: ''
  })
}
this.setState({saveBtn:"Save"});
this.setState({domBtn:"Save"});
}
).catch(err => {
});

}


    componentDidMount() {
      
    }
  render(props) {
    const { t } = this.props;
    return (
      <div>
                        <div className="page-header">
          <h3 className="page-title"> {t('SETTINGS')} </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="!#" onClick={event => event.preventDefault()}>{t('SETTINGS')}</a></li>
              <li className="breadcrumb-item active" aria-current="page">{t('USERINFO')}</li>
            </ol>
          </nav>
        </div>
        
        <div className="row">
          <div className="col-md-6 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">{t('USERINFO')}</h4>
                <form className="forms-sample">
                  <Form.Group>
                    <label htmlFor="exampleInputUsername1">{t('USERNAME')}</label>
                    <Form.Control type="text" id="" name="username" onChange={this.change.bind(this)} disabled value={this.props.data.username ? this.props.data.username : "Loading..."} placeholder="" size="lg" />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputEmail1">{t('EMAIL')}</label>
                    <Form.Control type="text" name="email" onChange={this.change.bind(this)} className="form-control" id="" defaultValue={this.props.data.email ? this.props.data.email : "Loading..."} placeholder="" />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputPassword1">Telegram Chat ID</label>
                    <Form.Control type="text" name="telegram" onChange={this.change.bind(this)} className="form-control" id="" defaultValue={this.props.data.telegram ? this.props.data.telegram : "Loading..."} placeholder="" />
                  </Form.Group>
                  <Form.Group style={{ marginBottom: "0.5rem"}}>
                  <label htmlFor="exampleInputPassword1">{t('CALLERNUMBERS')}</label>
                    <textarea disabled className="form-control select" name="fromNumbers">
                         {this.props.data.fromNumber}
                    </textarea>
                  </Form.Group>

                  <button type="button" name="infoBtn" onClick={this.Save.bind(this)} className="btn btn-gradient-primary btn-block mr-2">{this.state.saveBtn == "Save" ? "Save" : <img src={Loader}/>}</button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-6 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">{t('MANAGED')}{this.props.data.username ? "" : <img src={Loader}/>}
                  </h4>
                
                <form className="forms-sample">
                  <Form.Group>
                    <label htmlFor="exampleInputUsername1">Domain #1</label>
                    <Form.Control type="text" id="" name="d1" onChange={this.change.bind(this)} defaultValue={this.props.data.d1 ? this.props.data.d1 : "Not added yet"} placeholder="" size="lg" />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputEmail1">Domain #2</label>
                    <Form.Control type="text" name="d2" onChange={this.change.bind(this)} className="form-control" id="" defaultValue={this.props.data.d2 ? this.props.data.d2 : "Not added yet"} placeholder="" />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputPassword1">Domain #3</label>
                    <Form.Control type="text" name="d3" onChange={this.change.bind(this)} className="form-control" id="" defaultValue={this.props.data.d3 ? this.props.data.d3 : "Not added yet"} placeholder="" />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputPassword1">Domain #4</label>
                    <Form.Control type="text" name="d4" onChange={this.change.bind(this)} className="form-control" id="" defaultValue={this.props.data.d4 ? this.props.data.d4 : "Not added yet"} placeholder="" />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="exampleInputPassword1">Domain #5</label>
                    <Form.Control type="text" name="d5" onChange={this.change.bind(this)} className="form-control" id="" defaultValue={this.props.data.d5 ? this.props.data.d5 : "Not added yet"} placeholder="" />
                  </Form.Group>

                  <Form.Group>
                    <label htmlFor="exampleInputPassword1">Shortener Domain</label>
                    <Form.Control type="text" name="sd" onChange={this.change.bind(this)} className="form-control" id="" defaultValue={this.props.data.sd ? this.props.data.sd : "Not added yet"} placeholder="" />
                  </Form.Group>
                  <button type="button" name="domBtn" onClick={this.Save.bind(this)} className="btn btn-gradient-primary btn-block mr-2">{this.state.domBtn == "Save" ? "Save" : <img src={Loader}/>}</button>
                </form>
              </div>
            </div>
          </div>
          

      </div>
      
    </div>
    )
  }
}

export default withTranslation()(Settings)
