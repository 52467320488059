import React, { Component } from 'react'
import { ProgressBar } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import ReactPaginate from 'react-paginate';
import Loader from '../../assets/images/ajax-loader.gif';
import '../comps/load.css';
import { withTranslation } from 'react-i18next';
export class Visits extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      offset: 0,
      tableData: [null],
      orgtableData: [],
      perPage: 10,
      currentPage: 0,
      selectedFilter: "name",
      filterByText: "",
      modalDis: "none",
    }
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  inputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
      currentPage: selectedPage,
      offset: offset
    }, () => {
      this.loadMoreData()
    });

  };

  loadMoreData() {
    const data = this.state.orgtableData;

    const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      tableData: slice
    })

  }

  componentDidMount(props) {
    this.orders(this.props);

  }

  orders = (props) => {
    const ur = this.props.data.username;
    const authToken = this.props.data.authToken;
    const tf = { "username": ur, "authToken": authToken };

    axios.post("/masterapi/visits.php", tf).then((res) => {

      var tdata = res.data;

      var slice = tdata.slice(this.state.offset, this.state.offset + this.state.perPage)
      this.setState({
        pageCount: Math.ceil(tdata.length / this.state.perPage),
        orgtableData: tdata,
        tableData: slice
      })

    }
    ).catch(err => {
      this.setState({ tableData: null });
    });
  }

  det = e => {
    const ur = this.props.data.username;
    const authToken = this.props.data.authToken;
    const thisData = { "link": e.target.value, "username": ur, "authToken": authToken };
    this.setState({modalDis:"block"});
    axios.post("/masterapi/details.php", thisData).then((res) => {
      this.setState({modalDis:"none"});

      Swal.fire({
        title: 'Order Details',
        html: '<b>Order Name : </b>' + res.data.name + '<br>' +
          '<b>Device : </b>' + res.data.device + '<br>' +
          '<b>IMEI : </b>' + res.data.imei + '<br>' +
          '<b>Email : </b>' + res.data.email + '<br>' +
          '<b>Script : </b>' + res.data.script + '<br>' +
          '<b>After Login : </b>' + res.data.alogin + '<br>' +
          '<b>URL : </b>' + res.data.url + '<br>' +
          '<b>Expiry Date : </b>' + res.data.expires + '<br>' +
          '<b>Visits : </b>' + res.data.tvisits + '/' + res.data.avisits + '<br>' +
          '<b>Status : </b>' + res.data.status + '<br>' +
          '<b>Unlocked? : </b>' + res.data.unlocked + '<br>' +
          '<br>' +
          '',
        showCloseButton: false,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: '<i class="fa fa-thumbs-up"></i> OK!',
        confirmButtonAriaLabel: 'OK',
        cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
        cancelButtonAriaLabel: 'Thumbs down'
      })



    }
    ).catch(err => {
    });

  }

  block = (e) => {
    const link = e.target.value;
    const name = e.target.name;
    const ur = this.props.data.username;
    const authToken = this.props.data.authToken;
    var txt, btnTxt, tx;
    this.setState({modalDis:"block"});
    name == "Active" ? txt = "The Link will be blocked" : txt = "The Link will be Active";
    name == "Active" ? btnTxt = "Block it" : btnTxt = "Unblock it";
    name == "Active" ? tx = "Blocked" : tx = "Unblocked";

    Swal.fire({
      title: 'Are you sure?',
      text: txt,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: btnTxt
    }).then((result) => {
      if (result.isConfirmed) {
        const dta = { "link": link, "name": name, "username": ur, "authToken": authToken }
        axios.post("/masterapi/block.php", dta).then((res) => {
          this.setState({modalDis:"none"});
          if (res.data.process === "success") {

            Swal.fire(
              tx + '!',
              'Link ' + tx + ' Successfully.',
              'success'
            )
            this.orders();
          } else {
            this.setState({modalDis:"none"});
          }
        }
        ).catch(err => {
        });


      }
    })
  }





  del = (e) => {
    const link = e.target.value;
    const ur = this.props.data.username;
    const authToken = this.props.data.authToken;
    this.setState({modalDis:"block"});
    Swal.fire({
      title: 'Are you sure?',
      text: 'The link will be deleted',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        const dta = { "link": link, "username": ur, "authToken": authToken }
        axios.post("/masterapi/delv.php", dta).then((res) => {
          this.setState({modalDis:"none"});
          if (res.data.process === "success") {

            Swal.fire(
              'Deleted',
              'Link Deleted Successfully.',
              'success'
            )
            this.orders();
          } else {
            this.setState({modalDis:"none"});
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Something Went Wrong.!',
              footer: ''
            })

          }
        }
        ).catch(err => {
        });


      }
      this.setState({modalDis:"none"});
    })
  }


  render(props) {
    const { t } = this.props;
    const filteredList = this.state.tableData.filter((item) => {
      if (this.state.selectedFilter && this.state.filterByText) {
        if (this.state.selectedFilter === "id") {
          return (
            item[this.state.selectedFilter] &&
            item[this.state.selectedFilter].toString() ===
            this.state.filterByText.toString()
          );
        } else {
          return (
            item[this.state.selectedFilter] &&
            item[this.state.selectedFilter]
              .toLowerCase()
              .includes(this.state.filterByText.toLowerCase())
          );
        }
      } else {
        return item;
      }
    });
    var dat;
    this.props.ft == "Orders" ? dat = "All Orders" : dat = ""

    if (this.state.tableData == null) {
      return (
        <div>
          <div className="row">
            <div className="col-12 grid-margin text-center">
              <div className="card">
                <h2>{t('UNABLE')}</h2>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div>
        <div className="row">
        <div id="ac" style={{display:this.state.modalDis}} className="ac">
            <div className="ac-content">

              <div style={{ marginBottom: "0.5rem", textAlign: "center" }}>
              </div>
              <img src={Loader} />
            </div>
          </div>

          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">All Visits</h4>
                <div className="row">
                  <div className="col-md-6 stretch-card">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"} />
                  </div>
                  <div className="col-md-6 stretch-card justify-content-md-end mar">
                    <select
                      name="selectedFilter"
                      className='textIn'
                      onChange={this.inputChange}
                      value={this.state.selectedFilter}
                    >
                      <option disabled value="" required>
                      {t('SEARCHBY')}
                      </option>
                      <option value="Link">{t('LINK')}</option>
                      <option value="country">{t('COUNTRY')}</option>
                      <option value="browser">{t('BROWSER')}</option>
                      <option value="url">{t('URL')}</option>

                    </select>
                    <input
                      type="text"
                      value={this.state.filterByText}
                      name="filterByText"
                      className='textIn mar'
                      onChange={(e) => {
                        this.inputChange(e);
                      }}
                      placeholder="Search..."
                    />
                  </div>
                </div>
                <div className="table-hover table-responsive">

                  <table className="table">
                    <thead>
                      <tr>
                        <th> {t('COUNTRY')} </th>
                        <th> {t('BROWSER')} </th>
                        <th> {t('URL')} </th>
                        <th> {t('TIME')} </th>
                        <th> {t('VISITS')} </th>
                        <th> {t('ACTION')} </th>

                      </tr>
                    </thead>
                    <tbody>
                      {filteredList.map((e, i) => (
                        <tr key={e.id}>
                          <td> {e.country} </td>
                          <td> {e.browser} </td>
                          <td> {e.url} </td>
                          <td> {e.time} </td>
                          <td> {e.tvisit}</td>
                          <td><button type="button" className="btn btn-inverse-danger btn-icon mdi mdi-delete" value={e.link} onClick={this.del.bind(this)}></button>
                            <button className="btn btn-inverse-info btn-icon mdi mdi-information" value={e.link} onClick={this.det.bind(this)}></button>
                             </td>
                        </tr>

                      ))
                      }
                      {filteredList.length === 0 && (
                        <tr>
                          <td colSpan="8" style={{ textAlign: "center" }}>
                          {this.props.tableData ? "No Record Found" : <img src={Loader} />} 
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(Visits)
