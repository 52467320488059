import React, { Component,Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import TB from './comps/TB';
import Spinner from '../app/shared/Spinner';
import ProtectedRoute from './ProtectedRoute';
import Dashboard from './dashboard/Dashboard';
import Settings from './comps/Settings';
import UN from './comps/UN'
import Active from './comps/Active';
import Map from './comps/Map';
import Attempts from './comps/Attempts';
import Logout from './comps/Logout';
import Visits from './comps/Visits';
import Short from './comps/Short';
import FMI from './comps/FMI';
import SMS from './comps/SMS';
import CALL from './comps/CALL';
import Templates from './comps/Templates';
import Auto from './comps/Auto';
const Order = lazy(() => import('./comps/Order'));

const auth = localStorage.getItem("isAuthenticated");

const Login = lazy(() => import('./user-pages/Login'));
const Error = lazy(() => import('./error-pages/Error'));

class AppRoutes extends Component {
  render (props) {
    return (
      <Suspense fallback={<Spinner/>}>
        <Switch>
        <ProtectedRoute exact path="/" component={() => (<Redirect to="/dashboard" />)}/>
          <ProtectedRoute exact path="/dashboard" component={() => (<Dashboard data={this.props.data} />)}/>
          <ProtectedRoute exact path="/new-order" component={() => (<Order data={this.props.data} />)}/>

          <ProtectedRoute exact path="/map" component={() => (<Map data={this.props.data} />)}/>

          <ProtectedRoute exact path="/settings" component={() => (<Settings data={this.props.data} />)}/>
          <ProtectedRoute exact path="/unlocked" component={() => (<UN data={this.props.data} />)}/>
          <ProtectedRoute exact path="/orders" component={() => (<TB ft="Orders" data={this.props.data} />)}/>
          <ProtectedRoute exact path="/active" component={() => (<Active ft="Active" data={this.props.data} />)}/>
          <ProtectedRoute exact path="/visits" component={() => (<Visits ft="Visits" data={this.props.data} />)}/>
          <ProtectedRoute exact path="/attempts" component={() => (<Attempts ft="Attempts" data={this.props.data} />)}/>
          <ProtectedRoute exact path="/short" component={() => (<Short ft="Short" data={this.props.data} />)}/>
          <ProtectedRoute exact path="/fmi" component={() => (<FMI ft="fmi" data={this.props.data} />)}/>
          <ProtectedRoute exact path="/sms" component={() => (<SMS ft="sms" data={this.props.data} />)}/>
          <ProtectedRoute exact path="/call" component={() => (<CALL ft="call" data={this.props.data} />)}/>
          <ProtectedRoute exact path="/templates" component={() => (<Templates ft="templates" data={this.props.data} />)}/>
          <ProtectedRoute exact path="/autoremove" component={() => (<Auto ft="Autoremove" data={this.props.data} />)}/>
        
          <Route exact path="/login" component={()=>
                auth ? <Redirect to="/dashboard"/> : <Login/>
          }/>

              <Route path="/error" component={ Error } />
              <Route path="/logout" component={ Logout } />


          <Redirect to="/error" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;