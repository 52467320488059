import React, { Component } from 'react'
import { ProgressBar } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import ReactPaginate from 'react-paginate';
import Loader from '../../assets/images/ajax-loader.gif';
import '../comps/load.css';
import { withTranslation } from 'react-i18next';
export class Templates extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      offset: 0,
      tableData: [null],
      orgtableData: [],
      perPage: 10,
      currentPage: 0,
      selectedFilter: "id",
      filterByText: "",
      modalDis: "none",
      imei:"",
    }
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  inputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
      currentPage: selectedPage,
      offset: offset
    }, () => {
      this.loadMoreData()
    });

  };

  loadMoreData() {
    const data = this.state.orgtableData;

    const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      tableData: slice
    })

  }

  componentDidMount(props) {
    this.orders(this.props);

  }
  check = async (e) => {
    var us = this.props.data.username;
    
    try {
      const result = await Swal.fire({
        title: 'Add New Template',
        html:
        '<p>Use {{MODEL} for device, and {{URL}} for link.</p>' +
          '<input id="name" class="swal2-input" autofocus placeholder="Template Name">' +
          '<select class="swal2-input" id="type"><option disabled selected>Template Type?</option><option value="sms">sms</option><option value="call">call</option></select>' +
          '<textarea id="text" class="swal2-input" placeholder="Message"></textarea>',
        preConfirm: () => {
          return new Promise((resolve) => {
            if (true) {
              resolve([
                document.getElementById('name').value,
                document.getElementById('type').value,
                document.getElementById('text').value
              ]);
            }
          });
        }
      });
  
      if (result.value[1] === "sms" || result.value[1] === "call") {
        var name = result.value[0];
        var type = result.value[1];
        var text = result.value[2];
  
        const dta = { "name": name, "type": type, "text": text, "us": us }
        const res = await axios.post("/masterapi/addTemp.php", dta);
  
        if (res.data.process === "success") {
          Swal.fire(
            'Added',
            'Template Added Successfully.',
            'success'
          );
          await this.orders();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something Went Wrong!',
            footer: ''
          });
        }
      } else {
        Swal.fire({
          title: "Please choose the type too",
        });
      }
    } catch (err) {
      // Handle error if necessary
    }
  }
  
  orders = async () => {
    const ur = this.props.data.username;
    const authToken = this.props.data.authToken;
    const tf = { "username": ur, "authToken": authToken };
  
    try {
      const res = await axios.post("/masterapi/templates.php", tf);
      var tdata = res.data;
      console.log("orders called");
      var slice = tdata.slice(this.state.offset, this.state.offset + this.state.perPage);
      this.setState({
        pageCount: Math.ceil(tdata.length / this.state.perPage),
        orgtableData: tdata,
        tableData: slice
      });
    } catch (err) {
      this.setState({ tableData: null });
    }
  }
  

  del = (e) => {
    const tempId = e.target.value;
    const ur = this.props.data.username;
    const authToken = this.props.data.authToken;
    this.setState({modalDis:"block"});
    Swal.fire({
      title: 'Are you sure?',
      text: 'The Template will be deleted',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        const dta = { "tempId": tempId, "username": ur, "authToken": authToken }
        axios.post("/masterapi/deletetemp.php", dta).then((res) => {
          this.setState({modalDis:"none"});
          console.log(res.data);
          if (res.data.process === "success") {

            Swal.fire(
              'Deleted',
              'Template Deleted Successfully.',
              'success'
            )
            this.orders();
          } else {
            this.setState({modalDis:"none"});
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Something Went Wrong.!',
              footer: ''
            })

          }
        }
        ).catch(err => {
        });


      }
      this.setState({modalDis:"none"});
    })
  }
  edit = (e) => {
    const id = e.target.value;
    const ur = this.props.data.username;
    const authToken = this.props.data.authToken;
    this.setState({modalDis:"block"});
    const thisData = { "id": id, "username": ur, "authToken": authToken };
    axios.post("/masterapi/tempDetails.php", thisData).then(res => {
      this.setState({modalDis:"none"});
  
      Swal.fire({
        title: 'Template Details',
        html: '<form class="forms-sample">' +
          '<div><label class="lb">Name : </label><input id="name" type="text" class="form-control" value="' + res.data.name + '"/>' +
          '</div>' +
          '<div><label class="lb">Type : </label><input id="type" type="text" class="form-control" value="' + res.data.type + '"/>' +
          '</div>' +
          '<div><label class="lb">Message : </label><textarea id="text"  class="form-control" "/>' + res.data.text + '</textarea>' +
          '</div>' +
          '<input type="hidden" id="id" value="' + res.data.id + '"/></div>' +
          '</div>' +
  
  
          '',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Save'
      }).then((result) => {
        if (result.isConfirmed) {
  
          var name = document.getElementById("name").value;
          var type = document.getElementById("type").value;
          var text = document.getElementById("text").value;
          var id = document.getElementById("id").value;
          const orderData = { "name": name, "type": type, "text": text, "id": id, "username": ur};
  
  
          axios.post("/masterapi/editTemp.php", orderData).then((res) => {
            this.setState({modalDis:"none"});
    
            if (res.data.order == "success") {
              Swal.fire({
                title: 'Edited Successfully',
                icon: 'success',
                html: '<strong>Changes Saved Successfully...</strong>' +
                  '',
                showCloseButton: false,
                showCancelButton: false,
                focusConfirm: false,
                confirmButtonText: '<i class="fa fa-thumbs-up"></i> OK!',
                confirmButtonAriaLabel: 'OK',
                cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
                cancelButtonAriaLabel: 'Thumbs down'
              })
              this.orders();
            } else if (res.data.order == "linkExists") {
              this.setState({modalDis:"none"});
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'User already exists: change link letters.!',
                footer: ''
              })
            } else {
              this.setState({modalDis:"none"});
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something Went Wrong.!',
                footer: ''
              })
            }
          }
  
          ).catch(err => {
  
          });
        }
      })
  
  
    }).catch(err => {
    })
  
  
  }

  render(props) {
    const { t } = this.props;
    const filteredList = this.state.tableData.filter((item) => {
      if (this.state.selectedFilter && this.state.filterByText) {
        if (this.state.selectedFilter === "id") {
          return (
            item[this.state.selectedFilter] &&
            item[this.state.selectedFilter].toString() ===
            this.state.filterByText.toString()
          );
        } else {
          return (
            item[this.state.selectedFilter] &&
            item[this.state.selectedFilter]
              .toLowerCase()
              .includes(this.state.filterByText.toLowerCase())
          );
        }
      } else {
        return item;
      }
    });
    var dat;
    this.props.ft == "Orders" ? dat = "All Orders" : dat = ""

    if (this.state.tableData == null) {
      return (
        <div>
          <div className="row">
            <div className="col-12 grid-margin text-center">
              <div className="card">
                <h2>{t('UNABLE')}</h2>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div>
        <div className="row">
        <div id="ac" style={{display:this.state.modalDis}} className="ac">
            <div className="ac-content">

              <div style={{ marginBottom: "0.5rem", textAlign: "center" }}>
              </div>
              <img src={Loader} />
            </div>
          </div>

          <div className="col-12 grid-margin">
            
           
              <div className="card-body">
              
              <div className="row">
              <div className="col-md-6 stretch-card">
              <h4 className="card-title">{t('SMSCALLTEMPLATES')}</h4>
              </div>
                
                <div className="col-md-6 stretch-card justify-content-md-end mar">
                <button type="button" onClick={this.check.bind(this)} className="btn btn-gradient-primary form-control" style={{width:"200px",textAlign:"center", marginTop:"0px"}}> {t('ADDNEWTEMPLATE')} </button>
                </div>
                

</div>
</div>

<div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 stretch-card">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"} />
                  </div>
                  <div className="col-md-6 stretch-card justify-content-md-end mar">
                    <select
                      name="selectedFilter"
                      className='textIn'
                      onChange={this.inputChange}
                      value={this.state.selectedFilter}
                    >
                      <option disabled value="" required>
                      {t('SEARCHBY')}
                      </option>
                      <option value="name">{t('NAME')}</option>

                    </select>
                    <input
                      type="text"
                      value={this.state.filterByText}
                      name="filterByText"
                      className='textIn mar'
                      onChange={(e) => {
                        this.inputChange(e);
                      }}
                      placeholder="Search..."
                    />
                  </div>
                </div>
                <div className="table-hover table-responsive">

                  <table className="table">
                    <thead>
                      <tr>
                        <th> ID </th>
                        <th> {t('NAME')} </th>
                        <th> {t('TYPE')} </th>
                        <th> {t('MESSAGE')} </th>
                        <th> {t('ACTION')} </th>

                      </tr>
                    </thead>
                    <tbody>
                      {filteredList.map((e, i) => (
                        <tr key={e.id}>
                          <td> {e.id} </td>
                          <td> { e.name} </td>
                          <td> {e.type} </td>
                          <td> {e.text} </td>
                          <td><button type="button" className="btn btn-inverse-danger btn-icon mdi mdi-delete" value={e.id} onClick={this.del.bind(this)}></button>                            <button className="btn btn-inverse-info btn-icon" value={e.id} onClick={this.edit.bind(this)}>Edit</button>
                        
                             </td>
                        </tr>

                      ))
                      }
                      {filteredList.length === 0 && (
                        <tr>
                          <td colSpan="8" style={{ textAlign: "center" }}>
                          {this.props.tableData ? "No Record Found" : <img src={Loader} />} 
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(Templates)
