import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
import * as serviceWorker from './serviceWorker';

import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
console.log(navigator.language); // Check the detected language
console.log(navigator.languages);
i18n
  .use(LanguageDetector)
  .init({
    detection: {
      order: ['navigator', 'htmlTag', 'localStorage', 'path', 'subdomain'],
    },
    resources: {
      en: {
        translation: require('./locales/en/translation.json')
      },
      es: {
        translation: require('./locales/es/translation.json')
      }
      // Add more languages here
    },
    fallbackLng: 'en', // Default language
    debug: true, // Enable logging for debugging
    interpolation: {
      escapeValue: false // React handles safe interpolation
    }
  });

ReactDOM.render(
  <BrowserRouter basename="/">
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>,

  </BrowserRouter>
, document.getElementById('root'));

serviceWorker.unregister();