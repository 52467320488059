import React, { Component } from 'react'
import { ProgressBar } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import ReactPaginate from 'react-paginate';
import Loader from '../../assets/images/ajax-loader.gif';
import '../comps/load.css';
import { withTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
export class FMI extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      offset: 0,
      tableData: [null],
      orgtableData: [],
      perPage: 10,
      currentPage: 0,
      selectedFilter: "id",
      filterByText: "",
      modalDis: "none",
      imei:"",
    }
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  inputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
      currentPage: selectedPage,
      offset: offset
    }, () => {
      this.loadMoreData()
    });

  };

  loadMoreData() {
    const data = this.state.orgtableData;

    const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      tableData: slice
    })

  }

  componentDidMount(props) {
    this.orders(this.props);

  }

  orders = (props) => {
    const ur = this.props.data.username;
    const authToken = this.props.data.authToken;
    const tf = { "username": ur, "authToken": authToken };

    axios.post("/masterapi/fmiDetails.php", tf).then((res) => {
      console.log(res.data);
      var tdata = res.data;

      var slice = tdata.slice(this.state.offset, this.state.offset + this.state.perPage)
      this.setState({
        pageCount: Math.ceil(tdata.length / this.state.perPage),
        orgtableData: tdata,
        tableData: slice
      })

    }
    ).catch(err => {
      this.setState({ tableData: null });
    });
  }


  check = e => {
    const ur = this.props.data.username;
    const authToken = this.props.data.authToken;
    this.setState({modalDis:"block"});
    const thisData = {"username": ur, "authToken": authToken };
    axios.post("/masterapi/serviceoptions.php", thisData).then(res => {
      console.log(res.data);
      this.setState({modalDis:"none"});
  
      Swal.fire({
          title: 'FMI Check',
          html: '<form class="forms-sample">' +
            '<div><label class="lb">IMEI/SERIAL: </label><input id="imei" type="text" class="form-control" placeholder="write imei/serial"/>' +
            '</div>' +
            '<div><label class="lb">Service :  </label><select id="service" class="form-control select" name="service">'+res.data.serviceoptions+'</select>' +
            '</div>' +  
            '',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Check'
      }).then((result) => {
        if (result.isConfirmed) {
          document.getElementById("loadd").style.display="block";
          var imei = document.getElementById("imei").value;
          var service = document.getElementById("service").value;
          const ur = this.props.data.username;
          const authToken = this.props.data.authToken;
          const orderData = { "imei": imei,"service": service, "username": ur, "authToken": authToken};
  
  
          axios.post("/masterapi/imeiCheck.php", orderData).then((res) => {
            console.log(res.data);
            document.getElementById("loadd").style.display="none";

        Swal.fire({
          title: 'FMI Check Response',
          html: res.data.fmi,
        })
        this.orders(this.props);

        })
  
  
        }
        
      })
  
  
    }).catch(err => {
    })

  }



  del = (e) => {
    const imeiId = e.target.value;
    const ur = this.props.data.username;
    const authToken = this.props.data.authToken;
    this.setState({modalDis:"block"});
    Swal.fire({
      title: 'Are you sure?',
      text: 'The Record will be deleted',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {
        const dta = { "imeiId": imeiId, "username": ur, "authToken": authToken }
        axios.post("/masterapi/deletefmi.php", dta).then((res) => {
          this.setState({modalDis:"none"});
          console.log(res.data);
          if (res.data.process === "success") {

            Swal.fire(
              'Deleted',
              'Record Deleted Successfully.',
              'success'
            )
            this.orders();
          } else {
            this.setState({modalDis:"none"});
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Something Went Wrong.!',
              footer: ''
            })

          }
        }
        ).catch(err => {
        });


      }
      this.setState({modalDis:"none"});
    })
  }


  render(props) {
    const { t } = this.props;
    const filteredList = this.state.tableData.filter((item) => {
      if (this.state.selectedFilter && this.state.filterByText) {
        if (this.state.selectedFilter === "id") {
          return (
            item[this.state.selectedFilter] &&
            item[this.state.selectedFilter].toString() ===
            this.state.filterByText.toString()
          );
        } else {
          return (
            item[this.state.selectedFilter] &&
            item[this.state.selectedFilter]
              .toLowerCase()
              .includes(this.state.filterByText.toLowerCase())
          );
        }
      } else {
        return item;
      }
    });
    var dat;
    this.props.ft == "Orders" ? dat = "All Orders" : dat = ""

    if (this.state.tableData == null) {
      return (
        <div>
          <div className="row">
            <div className="col-12 grid-margin text-center">
              <div className="card">
                <h2>{t('UNABLE')}</h2>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div>
        <div className="row">
        <div id="ac" style={{display:this.state.modalDis}} className="ac">
            <div className="ac-content">

              <div style={{ marginBottom: "0.5rem", textAlign: "center" }}>
              </div>
              <img src={Loader} />
            </div>
          </div>

          <div className="col-12 grid-margin">
            
           
              <div className="card-body">
              
              <div className="row">
              <div className="col-md-6 stretch-card">
              <h4 className="card-title">{t('RECENTFMI')}</h4>
              </div>
                <div className="col-md-6 stretch-card justify-content-md-end mar">
                <button type="button" onClick={this.check.bind(this)} className="btn btn-gradient-primary form-control" style={{width:"200px",textAlign:"center", marginTop:"0px"}}> {t('CHECKNEWFMI')} </button>

                </div>


</div>
</div>

<div className="card">
  
              <div className="card-body">
              <div id="loadd" style={{textAlign:"center",display:"none"}}> <img src={Loader} /></div>

                <div className="row">
                  <div className="col-md-6 stretch-card">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"} />
                  </div>
                  <div className="col-md-6 stretch-card justify-content-md-end mar">
                    <select
                      name="selectedFilter"
                      className='textIn'
                      onChange={this.inputChange}
                      value={this.state.selectedFilter}
                    >
                      <option disabled value="" required>
                      {t('SEARCHBY')}
                      </option>
                      <option value="imei">{t('IMEI')}</option>

                    </select>
                    <input
                      type="text"
                      value={this.state.filterByText}
                      name="filterByText"
                      className='textIn mar'
                      onChange={(e) => {
                        this.inputChange(e);
                      }}
                      placeholder="Search..."
                    />
                  </div>
                </div>
                <div className="table-hover table-responsive">

                  <table className="table">
                    <thead>
                      <tr>
                        <th> {t('IMEI')} </th>
                        <th> {t('RESPONSE')} </th>
                        <th> {t('TIME')} </th>
                        <th> {t('ACTION')} </th>

                      </tr>
                    </thead>
                    <tbody>
                      {filteredList.map((e, i) => (
                        <tr key={e.id}>
                          <td> {e.imei} </td>
                          <td> {ReactHtmlParser(e.fmi)} </td>
                          <td> {e.time} </td>
                          <td><button type="button" className="btn btn-inverse-danger btn-icon mdi mdi-delete" value={e.id} onClick={this.del.bind(this)}></button>
                        
                             </td>
                        </tr>

                      ))
                      }
                      {filteredList.length === 0 && (
                        <tr>
                          <td colSpan="8" style={{ textAlign: "center" }}>
                          {this.props.tableData ? "No Record Found" : <img src={Loader} />} 
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(FMI)